var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "form-group" }, [
    _c(
      "select",
      {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.filters.selectSpace,
            expression: "filters.selectSpace",
          },
        ],
        staticClass: "form-control",
        attrs: { id: "nav-space", "data-cy": "space", name: "space" },
        on: {
          change: [
            function ($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function (o) {
                  return o.selected
                })
                .map(function (o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.$set(
                _vm.filters,
                "selectSpace",
                $event.target.multiple ? $$selectedVal : $$selectedVal[0]
              )
            },
            function ($event) {
              return _vm.onChange()
            },
          ],
        },
      },
      _vm._l(_vm.spaces, function (spaceOption) {
        return _c(
          "option",
          {
            key: spaceOption.id,
            domProps: {
              value: {
                id: spaceOption.id,
                name: spaceOption.name,
                cashless: spaceOption.cashless,
              },
            },
          },
          [_vm._v("\n      " + _vm._s(spaceOption.name) + "\n    ")]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }